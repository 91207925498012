import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { GetIndicativeRatesGQL, IndicativeRates } from '@graphql-types/payment-types';

@Injectable({
  providedIn: 'root',
})
export class RatesService {
  constructor(private getFXRatesGQL: GetIndicativeRatesGQL, private http: HttpClient) {}

  public getFXRates(): Observable<IndicativeRates[]> {
    return this.getFXRatesGQL.watch({}).valueChanges.pipe(
      take(1),
      map((result) => result?.data?.indicativeRates)
    );
  }

  public getFloatingRates(): Observable<FloatingRate[]> {
    return this.http.get<FloatingRate[]>('floating-rates');
  }
}

export interface FloatingRate {
  id: number;
  name: string;
  rate: number;
}
