import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { environment } from '@env/environment';

import { ErrorHandler } from '@app/core/services/api-error-handler/error-handler';

@Component({
  selector: 'fin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showHeader = false;
  showSidebar = false;
  showFooter = false;
  favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title) {
    ErrorHandler.init();
  }

  ngOnInit() {
    this.titleService.setTitle(environment.platform.title);
    if (this.favIcon) {
      this.favIcon.href = environment.platform.faviconUrl;
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showHeader = !!this.activatedRoute.firstChild?.snapshot.data.showHeader;
        this.showSidebar = !!this.activatedRoute.firstChild?.snapshot.data.showSidebar;
        this.showFooter = !!this.activatedRoute.firstChild?.snapshot.data.showFooter;
        // console.log(`showHeader:`, this.showHeader);
        // console.log(`showSidebar:`, this.showSidebar);
        // console.log(`showFooter:`, this.showFooter);
      }
    });
  }
}
