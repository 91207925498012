import { Injectable } from '@angular/core';
import { AUTH_API_KEY, environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { isArrayFull } from '@app/core/services/helpers.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyDocumentsService {
  private readonly companyDocumentsSubject: BehaviorSubject<CompanyDocument[]> = new BehaviorSubject<CompanyDocument[]>(
    []
  );
  readonly companyDocuments$: Observable<CompanyDocument[]> = this.companyDocumentsSubject.asObservable();

  constructor(private http: HttpClient, private readonly fb: FormBuilder) {}

  get companyDocuments(): CompanyDocument[] {
    return this.companyDocumentsSubject.value;
  }

  get companyDocumentsForSign(): CompanyDocument[] {
    if (this.companyDocuments?.length > 0) {
      return this.companyDocuments.filter((doc) => doc.need_sign);
    }
    return this.companyDocuments;
  }

  public getCompanyDocuments() {
    console.log(`this.companyDocumentsSubject`, this.companyDocumentsSubject);
    return this.http.get<any>(environment.userDocumentsUrl).pipe(
      map((documents: any) =>
        isArrayFull(documents)
          ? documents.map((doc: any) => ({
              ...doc,
              image: 'assets/images/components/shared/pdf-icon.svg',
            }))
          : []
      ),
      tap((companyDocuments: CompanyDocument[]) => {
        this.setCompanyDocuments(companyDocuments);
      }),
      shareReplay()
    );
  }

  private setCompanyDocuments(companyDocuments: CompanyDocument[]) {
    this.companyDocumentsSubject.next(companyDocuments);
  }

  getDocumentsSigns(company_uuid: string) {
    return this.http.get<any>(AUTH_API_KEY + 'document-signs/by-uuid', { params: { company_uuid } });
  }

  signDocuments(documents: SignedDocument[]) {
    return this.http.post(AUTH_API_KEY + 'document-signs/sign', { documents });
  }

  public generateCompanyDocumentsForm() {
    const userDocumentsForSignGroups = this.companyDocumentsForSign.map((doc) => {
      return this.fb.group({
        ...doc,
        signed: [null],
        sign_date: null,
      });
    });

    return this.fb.array(userDocumentsForSignGroups);
  }
}

export interface SignedDocument {
  name: string; // Unique key
  type: string;
  sign_date: Date;
}

export interface CompanyDocument {
  uuid?: any;
  title: string;
  description?: string;
  type?: string;
  name: string;
  file_link: string;
  need_sign?: boolean;
  image?: string;
}
