import { Component, ElementRef, OnInit } from '@angular/core';

import { debounceTime, tap } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LoadingService } from '@app/core/services';

// https://codepen.io/bali_balo/pen/OVamxy https://www.csscodelab.com/html-hexagon-grid-pierced-animation/
@UntilDestroy()
@Component({
  selector: 'fin-loader-screen',
  templateUrl: './loader-screen.component.html',
  styleUrls: ['./loader-screen.component.scss'],
})
export class LoaderScreenComponent implements OnInit {
  public cubes: number[] = Array.from(Array(4 * 4 * 4).keys());
  public cubeSides: number[] = Array.from(Array(4).keys());

  debounceTime = 200;
  loading = false;
  message = '';

  constructor(private loadingService: LoadingService, private elementRef: ElementRef) {}

  ngOnInit(): void {
    console.log('TCL: LoadingScreenComponent -> LOADER INITIALIZE');
    this.elementRef.nativeElement.style.display = 'none';
    this.loadingService.loadingStatus$
      .pipe(
        untilDestroyed(this),
        debounceTime(this.debounceTime),
        tap((status: boolean) => {
          console.log('TCL: LoadingScreenComponent -> status', status);
          this.elementRef.nativeElement.style.display = status ? 'block' : 'none';
          this.message = this.loadingService.message;
        })
      )
      .subscribe();
  }
}
