import { CustomErrorObject } from './models/custom-error.model';

export function getApiDomainErrorMessagesList(): { [key: string]: CustomErrorObject } {
  return {
    forbidden_resource: {
      code: 0,
      message: `forbidden_resource`,
      text: `Forbidden Resource`,
    },
    sellers_default_beneficiary_not_found: {
      code: 1,
      message: `sellers_default_beneficiary_not_found`,
      text: `Sellers Default Beneficiary Not Found`,
    },
    purchase_access_revoked: {
      code: 2,
      message: `purchase_access_revoked`,
      text: `Purchase Access Revoked`,
    },
    fund_manager_not_found: {
      code: 3,
      message: `fund_manager_not_found`,
      text: `Fund Manager Not Found`,
    },
    body_is_empty: {
      code: 4,
      message: `body_is_empty`,
      text: `Body Is Empty`,
    },
    payer_not_found: {
      code: 5,
      message: `payer_not_found`,
      text: `Payer Not Found`,
    },
    invoices_not_found: {
      code: 6,
      message: `invoices_not_found`,
      text: `Invoices Not Found`,
    },
    invoice_not_found: {
      code: 7,
      message: `invoice_not_found`,
      text: `Invoice Not Found`,
    },
    file_not_provided: {
      code: 8,
      message: `file_not_provided`,
      text: `File Not Provided`,
    },
    company_does_not_exist: {
      code: 9,
      message: `company_does_not_exist`,
      text: `Company Does Not Exist`,
    },
    parsing_file_error: {
      code: 10,
      message: `parsing_file_error`,
      text: 'Parsing File Error',
    },
    limit_size_is_300_kb: {
      code: 11,
      message: `limit_size_is_300_kb`,
      text: `Limit Size Is 300 Kb`,
    },
    only_fund_manager_can_upload_invoices_in_this_route: {
      code: 12,
      message: `only_fund_manager_can_upload_invoices_in_this_route`,
      text: `Only Fund Manager Can Upload Invoices In This Route`,
    },
    validation_failed_numeric_string_is_expected: {
      code: 13,
      message: `validation_failed_numeric_string_is_expected`,
      text: `Validation Failed Numeric String Is Expected`,
    },
    error_executing_validation: {
      code: 14,
      message: `error_executing_validation`,
      text: `Error Executing Validation`,
    },
    model_not_found: {
      code: 15,
      message: `model_not_found`,
      text: `Model Not Found`,
    },
    query_param_must_be_array: {
      code: 16,
      message: `query_param_must_be_array`,
      text: `Query Param Must Be Array`,
    },
    payer_belong_to_other_fund_manager: {
      code: 17,
      message: `payer_belong_to_other_fund_manager`,
      text: `Payer Belong To Other Fund Manager`,
    },
    validation_failed_value_must_be_a_number: {
      code: 18,
      message: `validation_failed_value_must_be_a_number`,
      text: `Validation Failed Value Must Be A Number`,
    },
    validation_failed_value_must_be_a_string: {
      code: 19,
      message: `validation_failed_value_must_be_a_string`,
      text: `Validation Failed Value Must Be A String`,
    },
    validation_failed_field_must_be_a_number: {
      code: 20,
      message: `validation_failed_field_must_be_a_number`,
      text: `Validation Failed Field Must Be A Number`,
    },
    wallet_not_found: {
      code: 21,
      message: `wallet_not_found`,
      text: `Wallet Not Found`,
    },
    not_implemented: {
      code: 22,
      message: `not_implemented`,
      text: `Not Implemented`,
    },
    can_not_find_some_payers_from_ids_list: {
      code: 23,
      message: `can_not_find_some_payers_from_ids_list`,
      text: `Can Not Find Some Payers From Ids List`,
    },
    find_condition_is_required: {
      code: 24,
      message: `find_condition_is_required`,
      text: `Find Condition Is Required`,
    },
    funder_not_found: {
      code: 25,
      message: `funder_not_found`,
      text: `Funder Not Found`,
    },
    broken_file: {
      code: 26,
      message: `broken_file`,
      text: 'Broken File',
    },
    invoice_ids_are_required: {
      code: 27,
      message: `invoice_ids_are_required`,
      text: `Invoice Ids Are Required`,
    },
    your_role_is_not_invoice_seller_or_invoice_payer: {
      code: 28,
      message: `your_role_is_not_invoice_seller_or_invoice_payer`,
      text: `Your Role Is Not Invoice Seller Or Invoice Payer`,
    },
    can_not_update_is_sellers_restricted: {
      code: 29,
      message: `can_not_update_is_sellers_restricted`,
      text: `Can Not Update Is Sellers Restricted`,
    },
    some_invoices_do_not_exist: {
      code: 30,
      message: `some_invoices_do_not_exist`,
      text: `Some Invoices Do Not Exist`,
    },
    seller_not_found: {
      code: 31,
      message: `seller_not_found`,
      text: 'Seller Not Found',
    },
    payer_id_is_required: {
      code: 32,
      message: `payer_id_is_required`,
      text: `Payer Id Is Required`,
    },
    invoice_no_must_be_min_one_char_on_number: {
      code: 33,
      message: `invoice_no_must_be_min_one_char_on_number`,
      text: `Invoice No Must Be Min One Char On Number`,
    },
    amount_is_required: {
      code: 34,
      message: `amount_is_required`,
      text: `Amount Is Required`,
    },
    amount_must_be_a_positive_number: {
      code: 35,
      message: `amount_must_be_a_positive_number`,
      text: `Amount Must Be A Positive Number`,
    },
    payer_must_be_true_sell: {
      code: 36,
      message: `payer_must_be_true_sell`,
      text: `Payer Must Be True Sell`,
    },
    is_sellers_restricted: {
      code: 37,
      message: `is_sellers_restricted`,
      text: `Is Sellers Restricted`,
    },
    some_currencies_not_supported: {
      code: 38,
      message: `some_currencies_not_supported`,
      text: `Some Currencies Not Supported`,
    },
    seller_payer_relationship_not_found: {
      code: 39,
      message: `seller_payer_relationship_not_found`,
      text: `Seller Payer Relationship Not Found`,
    },
    real_maturity_date_not_provided: {
      code: 40,
      message: `real_maturity_date_not_provided`,
      text: `Real Maturity Date Not Provided`,
    },
    user_maturity_date_must_be_greater_than_issue_date: {
      code: 41,
      message: `user_maturity_date_must_be_greater_than_issue_date`,
      text: `User Maturity Date Must Be Greater Than Issue Date`,
    },
    po_ref_must_be_min_one_char_on_number: {
      code: 42,
      message: `po_ref_must_be_min_one_char_on_number`,
      text: `Po Ref Must Be Min One Char On Number`,
    },
    something_went_wrong: {
      code: 43,
      message: `something_went_wrong`,
      text: `Something Went Wrong`,
    },
    invoice_uploading_available_only_for_payers_with_activated_prepayment: {
      code: 44,
      message: `invoice_uploading_available_only_for_payers_with_activated_prepayment`,
      text: `Invoice Uploading Available Only For Payers With Activated Prepayment`,
    },
    reference_is_required: {
      code: 45,
      message: `reference_is_required`,
      text: `Reference Is Required`,
    },
    repayment_reference_was_not_attached: {
      code: 46,
      message: `repayment_reference_was_not_attached`,
      text: `Repayment Reference Was Not Attached`,
    },
    repayment_reference_can_not_be_deleted: {
      code: 47,
      message: `repayment_reference_can_not_be_deleted`,
      text: `Repayment Reference Can Not Be Deleted`,
    },
    xlsx_file_generate_error: {
      code: 48,
      message: `xlsx_file_generate_error`,
      text: `Xlsx File Generate Error`,
    },
    any_of_invoices_status_incorrect_or_not_funded: {
      code: 49,
      message: `any_of_invoices_status_incorrect_or_not_funded`,
      text: `Any Of Invoices Status Incorrect Or Not Funded`,
    },
    cron_pattern_to_change_must_be_a_string: {
      code: 50,
      message: `cron_pattern_to_change_must_be_a_string`,
      text: `Cron Pattern To Change Must Be A String`,
    },
    please_set_correct_value_for_cut_off_time_in_environment: {
      code: 51,
      message: `please_set_correct_value_for_cut_off_time_in_environment`,
      text: `Please Set Correct Value For Cut Off Time In Environment`,
    },
    wrong_cron_pattern: {
      code: 52,
      message: `wrong_cron_pattern`,
      text: `Wrong Cron Pattern`,
    },
    gln_must_be_longer_than_or_equal_to_13_characters: {
      code: 53,
      message: `gln_must_be_longer_than_or_equal_to_13_characters`,
      text: `Gln Must Be Longer Than Or Equal To 13 Characters`,
    },
    gln_must_contains_only_numbers: {
      code: 54,
      message: `gln_must_contains_only_numbers`,
      text: `Gln Must Contains Only Numbers`,
    },
    can_not_construct_valid_moment_object_from_first_date_value: {
      code: 55,
      message: `can_not_construct_valid_moment_object_from_first_date_value`,
      text: `Can Not Construct Valid Moment Object From First Date Value`,
    },
    can_not_construct_valid_moment_object_from_second_date_value: {
      code: 56,
      message: `can_not_construct_valid_moment_object_from_second_date_value`,
      text: `Can Not Construct Valid Moment Object From Second Date Value`,
    },
    env_cut_off_time_is_not_correct_format: {
      code: 57,
      message: `env_cut_off_time_is_not_correct_format`,
      text: `Env Cut Off Time Is Not Correct Format`,
    },
    validation_failed: {
      code: 58,
      message: `validation_failed`,
      text: `Validation Failed`,
    },
    error_validation_record_existence: {
      code: 59,
      message: `error_validation_record_existence`,
      text: `Error Validation Record Existence`,
    },
    validator_check_exists_number_is_expected: {
      code: 60,
      message: `validator_check_exists_number_is_expected`,
      text: `Validator Check Exists Number Is Expected`,
    },
    seller_payer_invoice_no_exists: {
      code: 61,
      message: `seller_payer_invoice_no_exists`,
      text: `Seller Payer Invoice No Exists`,
    },
    has_no_access_to_execute_this_route: {
      code: 62,
      message: `has_no_access_to_execute_this_route`,
      text: `Has No Access To Execute This Route`,
    },
    not_found_payment_metadata_for_currency_and_country_code: {
      code: 63,
      message: `not_found_payment_metadata_for_currency_and_country_code`,
      text: `Not Found Payment Metadata For Currency And Country Code`,
    },
    database_unavailable: {
      code: 64,
      message: `database_unavailable`,
      text: `Database Unavailable`,
    },
    model_field_list_field_is_not_specified_in_class_but_in_is_needed_for_updating_process: {
      code: 65,
      message: `model_field_list_field_is_not_specified_in_class_but_in_is_needed_for_updating_process`,
      text: `Model Field List Field Is Not Specified In Class But In Is Needed For Updating Process`,
    },
    error_while_creating_model: {
      code: 66,
      message: `error_while_creating_model`,
      text: `Error While Creating Model`,
    },
    amount_can_not_be_zero: {
      code: 67,
      message: `amount_can_not_be_zero`,
      text: `Amount Can Not Be Zero`,
    },
    error_when_get_business_days_currency_pairs_from_s3: {
      code: 68,
      message: `error_when_get_business_days_currency_pairs_from_s3`,
      text: `Error When Get Business Days Currency Pairs From S3`,
    },
    empty_data_in_business_days_currency_pairs_from_s3: {
      code: 69,
      message: `empty_data_in_business_days_currency_pairs_from_s3`,
      text: `Empty Data In Business Days Currency Pairs From S3`,
    },
    company_representative_not_found: {
      code: 70,
      message: `company_representative_not_found`,
      text: `Company Representative Not Found`,
    },
    user_with_such_email_exists: {
      code: 71,
      message: `user_with_such_email_exists`,
      text: `User With Such Email Exists`,
    },
    can_be_linked_only_to_one_company: {
      code: 72,
      message: `can_be_linked_only_to_one_company`,
      text: `Can Be Linked Only To One Company`,
    },
    field_does_not_exist: {
      code: 73,
      message: `field_does_not_exist`,
      text: `Field Does Not Exist`,
    },
    sign_uuid_must_not_be_equal_sign_proof_uuid: {
      code: 74,
      message: `sign_uuid_must_not_be_equal_sign_proof_uuid`,
      text: `Sign Uuid Must Not Be Equal Sign Proof Uuid`,
    },
    documents_not_found: {
      code: 75,
      message: `documents_not_found`,
      text: `Documents Not Found`,
    },
    company_not_found: {
      code: 76,
      message: `company_not_found`,
      text: `Company Not Found`,
    },
    document_company_mismatch: {
      code: 77,
      message: `document_company_mismatch`,
      text: `Document Company Mismatch`,
    },
    not_sign_document_type_provided: {
      code: 78,
      message: `not_sign_document_type_provided`,
      text: `Not Sign Document Type Provided`,
    },
    not_sign_proof_document_type_provided: {
      code: 79,
      message: `not_sign_proof_document_type_provided`,
      text: `Not Sign Proof Document Type Provided`,
    },
    role_does_not_have_company_representative: {
      code: 80,
      message: `role_does_not_have_company_representative`,
      text: `Role Does Not Have Company Representative`,
    },
    can_not_insert_fund_management_event_logs_into_the_database: {
      code: 81,
      message: `can_not_insert_fund_management_event_logs_into_the_database`,
      text: `Can Not Insert Fund Management Event Logs Into The Database`,
    },
    document_with_uuid_not_found: {
      code: 82,
      message: `document_with_uuid_not_found`,
      text: `Document With Uuid Not Found`,
    },
    document_does_not_belong_to_this_funder: {
      code: 83,
      message: `document_does_not_belong_to_this_funder`,
      text: `Document Does Not Belong To This Funder`,
    },
    only_one_of_the_investment_strategy_id_of_managed_funds_id_must_be_provided: {
      code: 84,
      message: `only_one_of_the_investment_strategy_id_of_managed_funds_id_must_be_provided`,
      text: `Only One Of The Investment Strategy Id Of Managed Funds Id Must Be Provided`,
    },
    funder_seller_limits_not_found: {
      code: 85,
      message: `funder_seller_limits_not_found`,
      text: `Funder Seller Limits Not Found`,
    },
    funder_seller_limit_exist: {
      code: 86,
      message: `funder_seller_limit_exist`,
      text: `Funder Seller Limit Exist`,
    },
    your_role_is_not_portal_admin_or_not_fully_registered: {
      code: 87,
      message: `your_role_is_not_portal_admin_or_not_fully_registered`,
      text: `Your Role Is Not Portal Admin Or Not Fully Registered`,
    },
    your_role_is_not_portal_admin_or_funder: {
      code: 88,
      message: `your_role_is_not_portal_admin_or_funder`,
      text: `Your Role Is Not Portal Admin Or Funder`,
    },
    funder_is_already_created: {
      code: 89,
      message: `funder_is_already_created`,
      text: `Funder Is Already Created`,
    },
    name_should_not_be_empty: {
      code: 90,
      message: `name_should_not_be_empty`,
      text: `Name Should Not Be Empty`,
    },
    bad_checkout_start_days: {
      code: 91,
      message: `bad_checkout_start_days`,
      text: `Bad Checkout Start Days`,
    },
    you_have_no_policy_to_select_payment_dates: {
      code: 92,
      message: `you_have_no_policy_to_select_payment_dates`,
      text: `You Have No Policy To Select Payment Dates`,
    },
    currencies_for_calculation_display_is_required: {
      code: 93,
      message: `currencies_for_calculation_display_is_required`,
      text: `Currencies For Calculation Display Is Required`,
    },
    allocation_type_not_supported: {
      code: 94,
      message: `allocation_type_not_supported`,
      text: `Allocation Type Not Supported`,
    },
    your_role_is_not_fund_manager_or_funder: {
      code: 95,
      message: `your_role_is_not_fund_manager_or_funder`,
      text: `Your Role Is Not Fund Manager Or Funder`,
    },
    you_do_not_manage_this_funder: {
      code: 96,
      message: `you_do_not_manage_this_funder`,
      text: `You Do Not Manage This Funder`,
    },
    user_uuid_was_not_set: {
      code: 97,
      message: `user_uuid_was_not_set`,
      text: `User Uuid Was Not Set`,
    },
    funder_not_found_while_update_process: {
      code: 98,
      message: `funder_not_found_while_update_process`,
      text: `Funder Not Found While Update Process`,
    },
    can_not_update_funder_please_see_api_logs: {
      code: 99,
      message: `can_not_update_funder_please_see_api_logs`,
      text: `Can Not Update Funder Please See Api Logs`,
    },
    reg_no_or_tax_id_is_busy: {
      code: 100,
      message: `reg_no_or_tax_id_is_busy`,
      text: `Reg No Or Tax Id Is Busy`,
    },
    can_not_insert_investment_strategy_into_the_database: {
      code: 101,
      message: `can_not_insert_investment_strategy_into_the_database`,
      text: `Can Not Insert Investment Strategy Into The Database`,
    },
    can_not_update_strategy: {
      code: 102,
      message: `can_not_update_strategy`,
      text: `Can Not Update Strategy`,
    },
    funder_payer_unpause_duration_is_not_supported: {
      code: 103,
      message: `funder_payer_unpause_duration_is_not_supported`,
      text: `Funder Payer Unpause Duration Is Not Supported`,
    },
    payer_id_draft: {
      code: 104,
      message: `payer_id_draft`,
      text: `Payer Id Draft`,
    },
    funder_id_is_required: {
      code: 105,
      message: `funder_id_is_required`,
      text: `Funder Id Is Required`,
    },
    strategy_not_found: {
      code: 106,
      message: `strategy_not_found`,
      text: `Strategy Not Found`,
    },
    strategy_amount_can_not_be_less_than_currently_invested_amount: {
      code: 107,
      message: `strategy_amount_can_not_be_less_than_currently_invested_amount`,
      text: `Strategy Amount Can Not Be Less Than Currently Invested Amount`,
    },
    funder_has_empty_field_fund_manager_entity_id: {
      code: 108,
      message: `funder_has_empty_field_fund_manager_entity_id`,
      text: `Funder Has Empty Field Fund Manager Entity Id`,
    },
    not_banking_day: {
      code: 109,
      message: `not_banking_day`,
      text: `Not Banking Day`,
    },
    strategy_wallet_not_found: {
      code: 110,
      message: `strategy_wallet_not_found`,
      text: `Strategy Wallet Not Found`,
    },
    not_enough_available_amount_in_wallet: {
      code: 111,
      message: `not_enough_available_amount_in_wallet`,
      text: 'Not enough available amount in wallet',
    },
    not_enough_available_amount_for_initial_margin: {
      code: 112,
      message: `not_enough_available_amount_for_initial_margin`,
      text: `Not Enough Available Amount For Initial Margin`,
    },
    liquidity_provider_is_not_available: {
      code: 113,
      message: `liquidity_provider_is_not_available`,
      text: `Liquidity Provider Is Not Available`,
    },
    cannot_exceed_maximum_investment_amount: {
      code: 114,
      message: `cannot_exceed_maximum_investment_amount`,
      text: `Cannot Exceed Maximum Investment Amount`,
    },
    limit_amount_can_not_less_than_draw_down_or_utilization: {
      code: 115,
      message: `limit_amount_can_not_less_than_draw_down_or_utilization`,
      text: `Limit Amount Can Not Less Than Draw Down Or Utilization`,
    },
    managed_fund_rejected: {
      code: 116,
      message: `managed_fund_rejected`,
      text: `Managed Fund Rejected`,
    },
    managed_fund_revoked: {
      code: 117,
      message: `managed_fund_revoked`,
      text: `Managed Fund Revoked`,
    },
    managed_fund_not_approved: {
      code: 118,
      message: `managed_fund_not_approved`,
      text: `Managed Fund Not Approved`,
    },
    investment_strategy_not_found: {
      code: 119,
      message: `investment_strategy_not_found`,
      text: `Investment Strategy Not Found`,
    },
    investment_strategy_approved_or_rejected: {
      code: 120,
      message: `investment_strategy_approved_or_rejected`,
      text: `Investment Strategy Approved Or Rejected`,
    },
    strategy_name_must_be_unique: {
      code: 121,
      message: `strategy_name_must_be_unique`,
      text: `Strategy Name Must Be Unique`,
    },
    limit_per_payer_must_be_greater_than_zero: {
      code: 122,
      message: `limit_per_payer_must_be_greater_than_zero`,
      text: `Limit Per Payer Must Be Greater Than Zero`,
    },
    has_strategy_with_such_payer: {
      code: 123,
      message: `has_strategy_with_such_payer`,
      text: `Has Strategy With Such Payer`,
    },
    must_be_one_payer: {
      code: 124,
      message: `must_be_one_payer`,
      text: `Must Be One Payer`,
    },
    some_payers_do_not_exist: {
      code: 125,
      message: `some_payers_do_not_exist`,
      text: `Some Payers Do Not Exist`,
    },
    beneficiary_uuid_for_payout_currency_miss_match: {
      code: 126,
      message: `beneficiary_uuid_for_payout_currency_miss_match`,
      text: `Beneficiary Uuid For Payout Currency Miss Match`,
    },
    seller_payers_key_must_be_one_payer: {
      code: 127,
      message: `seller_payers_key_must_be_one_payer`,
      text: `Seller Payers Key Must Be One Payer`,
    },
    wallet_uuid_is_incorrect: {
      code: 128,
      message: `wallet_uuid_is_incorrect`,
      text: `Wallet Uuid Is Incorrect`,
    },
    wallet_available_is_less_than_wallet_amount: {
      code: 129,
      message: `wallet_available_is_less_than_wallet_amount`,
      text: `Wallet Available Is Less Than Wallet Amount`,
    },
    has_discount_strategy_with_such_invoice_seller: {
      code: 130,
      message: `has_discount_strategy_with_such_invoice_seller`,
      text: `Has Discount Strategy With Such Invoice Seller`,
    },
    some_sellers_do_not_exist: {
      code: 131,
      message: `some_sellers_do_not_exist`,
      text: `Some Sellers Do Not Exist`,
    },
    management_funds_limit_not_found_for_currency: {
      code: 132,
      message: `management_funds_limit_not_found_for_currency`,
      text: `Management Funds Limit Not Found For Currency`,
    },
    managed_fund_limit_exceeded: {
      code: 133,
      message: `managed_fund_limit_exceeded`,
      text: 'Managed fund limit exceeded',
    },
    beneficiary_for_commission_payout_not_found: {
      code: 134,
      message: `beneficiary_for_commission_payout_not_found`,
      text: `Beneficiary For Commission Payout Not Found`,
    },
    beneficiary_for_commission_payout_not_valid: {
      code: 135,
      message: `beneficiary_for_commission_payout_not_valid`,
      text: `Beneficiary For Commission Payout Not Valid`,
    },
    beneficiary_for_commission_payout_does_not_belong_to_fund_manager: {
      code: 136,
      message: `beneficiary_for_commission_payout_does_not_belong_to_fund_manager`,
      text: `Beneficiary For Commission Payout Does Not Belong To Fund Manager`,
    },
    managed_fund_not_found: {
      code: 137,
      message: `managed_fund_not_found`,
      text: `Managed Fund Not Found`,
    },
    fund_manager_is_not_allowed_to_create_strategy_for_managed_fund: {
      code: 138,
      message: `fund_manager_is_not_allowed_to_create_strategy_for_managed_fund`,
      text: `Fund Manager Is Not Allowed To Create Strategy For Managed Fund`,
    },
    beneficiary_uuid_for_payout_not_found: {
      code: 139,
      message: `beneficiary_uuid_for_payout_not_found`,
      text: `Beneficiary Uuid For Payout Not Found`,
    },
    all_shares_sum_can_not_be_more_than_one_hundred_percent: {
      code: 140,
      message: `all_shares_sum_can_not_be_more_than_one_hundred_percent`,
      text: `All Shares Sum Can Not Be More Than One Hundred Percent`,
    },
    refresh_interval_and_refreshed_at_must_be_set_in_order_to_execute_payer_discounting: {
      code: 141,
      message: `refresh_interval_and_refreshed_at_must_be_set_in_order_to_execute_payer_discounting`,
      text: `Refresh Interval And Refreshed At Must Be Set In Order To Execute Payer Discounting`,
    },
    unique_constraint_violated_while_insert_invoice: {
      code: 142,
      message: `unique_constraint_violated_while_insert_invoice`,
      text: `Unique Constraint Violated While Insert Invoice`,
    },
    can_not_insert_invoice_into_the_database: {
      code: 143,
      message: `can_not_insert_invoice_into_the_database`,
      text: `Can Not Insert Invoice Into The Database`,
    },
    can_not_update_invoice_in_the_database: {
      code: 144,
      message: `can_not_update_invoice_in_the_database`,
      text: `Can Not Update Invoice In The Database`,
    },
    invoice_ids_must_be_valid_numbered_array: {
      code: 145,
      message: `invoice_ids_must_be_valid_numbered_array`,
      text: `Invoice Ids Must Be Valid Numbered Array`,
    },
    invoice_no_not_found: {
      code: 146,
      message: `invoice_no_not_found`,
      text: `Invoice No Not Found`,
    },
    custom_invoice_workflow_settings_exists: {
      code: 147,
      message: `custom_invoice_workflow_settings_exists`,
      text: `Custom Invoice Workflow Settings Exists`,
    },
    document_added_to_attachment_handler: {
      code: 148,
      message: `document_added_to_attachment_handler`,
      text: `Document Added To Attachment Handler`,
    },
    too_many_portal_admin_params: {
      code: 149,
      message: `too_many_portal_admin_params`,
      text: `Too Many Portal Admin Params`,
    },
    no_one_funded_invoice_found: {
      code: 150,
      message: `no_one_funded_invoice_found`,
      text: `No One Funded Invoice Found`,
    },
    edi_traded_invoice_requires_gln_in_invoice_seller: {
      code: 151,
      message: `edi_traded_invoice_requires_gln_in_invoice_seller`,
      text: `Edi Traded Invoice Requires Gln In Invoice Seller`,
    },
    invoice_no_must_be_unique: {
      code: 152,
      message: `invoice_no_must_be_unique`,
      text: `Invoice No Must Be Unique`,
    },
    some_invoices_without_mandatory_document_types: {
      code: 153,
      message: `some_invoices_without_mandatory_document_types`,
      text: `Some Invoices Without Mandatory Document Types`,
    },
    only_seller_and_payer_types_can_be_passed_to_set_declined_status_method: {
      code: 154,
      message: `only_seller_and_payer_types_can_be_passed_to_set_declined_status_method`,
      text: `Only Seller And Payer Types Can Be Passed To Set Declined Status Method`,
    },
    not_supported_invoice_upload_type: {
      code: 155,
      message: `not_supported_invoice_upload_type`,
      text: `Not Supported Invoice Upload Type`,
    },
    account_uuid_must_be_a_uuid: {
      code: 156,
      message: `account_uuid_must_be_a_uuid`,
      text: `Account Uuid Must Be A Uuid`,
    },
    account_not_found_for_current_company: {
      code: 157,
      message: `account_not_found_for_current_company`,
      text: `Account Not Found For Current Company`,
    },
    your_role_is_not_invoice_seller: {
      code: 158,
      message: `your_role_is_not_invoice_seller`,
      text: `Your Role Is Not Invoice Seller`,
    },
    need_one_currency_per_order_form: {
      code: 159,
      message: `need_one_currency_per_order_form`,
      text: 'Need one currency per order form',
    },
    payers_not_found: {
      code: 160,
      message: `payers_not_found`,
      text: `Payers Not Found`,
    },
    payers_not_found_in_sso: {
      code: 161,
      message: `payers_not_found_in_sso`,
      text: `Payers Not Found In Sso`,
    },
    purchase_access_revoked_for_payers: {
      code: 162,
      message: `purchase_access_revoked_for_payers`,
      text: 'Purchase access revoked for payers',
    },
    account_does_not_exist: {
      code: 163,
      message: `account_does_not_exist`,
      text: `Account Does Not Exist`,
    },
    invoice_already_has_contract: {
      code: 164,
      message: `invoice_already_has_contract`,
      text: `Invoice Already Has Contract`,
    },
    invoices_not_provided: {
      code: 165,
      message: `invoices_not_provided`,
      text: `Invoices Not Provided`,
    },
    only_one_seller_receiving_currency_allowed: {
      code: 166,
      message: 'only_one_seller_receiving_currency_allowed',
      text: 'Only one seller receiving currency allowed',
    },
    too_many_invoices: {
      code: 167,
      message: `too_many_invoices`,
      text: 'Too many Zinvoices',
    },
    invoice_no_is_empty: {
      code: 168,
      message: `invoice_no_is_empty`,
      text: `Invoice No Is Empty`,
    },
    maturity_date_is_required: {
      code: 169,
      message: `maturity_date_is_required`,
      text: `Maturity Date Is Required`,
    },
    company_no_is_empty: {
      code: 170,
      message: `company_no_is_empty`,
      text: `Company No Is Empty`,
    },
    invoice_amount_is_empty: {
      code: 171,
      message: `invoice_amount_is_empty`,
      text: `Invoice Amount Is Empty`,
    },
    seller_not_recognized: {
      code: 172,
      message: `seller_not_recognized`,
      text: `Seller Not Recognized`,
    },
    invoice_no_already_exists_in_upload_list: {
      code: 173,
      message: `invoice_no_already_exists_in_upload_list`,
      text: `Invoice No Already Exists In Upload List`,
    },
    invoice_amount_must_be_a_number: {
      code: 174,
      message: `invoice_amount_must_be_a_number`,
      text: `Invoice Amount Must Be A Number`,
    },
    invoice_no_must_contain_at_least_one_char_or_number: {
      code: 175,
      message: `invoice_no_must_contain_at_least_one_char_or_number`,
      text: `Invoice No Must Contain At Least One Char Or Number`,
    },
    invoice_no_unique_per_seller: {
      code: 176,
      message: `invoice_no_unique_per_seller`,
      text: `Invoice No Unique Per Seller`,
    },
    maturity_date_must_be_in_future: {
      code: 177,
      message: `maturity_date_must_be_in_future`,
      text: `Maturity Date Must Be In Future`,
    },
    goods_delivery_date_is_required: {
      code: 178,
      message: `goods_delivery_date_is_required`,
      text: `Goods Delivery Date Is Required`,
    },
    goods_delivery_date_is_invalid_date_format: {
      code: 179,
      message: `goods_delivery_date_is_invalid_date_format`,
      text: `Goods Delivery Date Is Invalid Date Format`,
    },
    goods_delivery_date_out_of_valid_range: {
      code: 180,
      message: `goods_delivery_date_out_of_valid_range`,
      text: `Goods Delivery Date Out Of Valid Range`,
    },
    issue_date_is_required: {
      code: 181,
      message: `issue_date_is_required`,
      text: `Issue Date Is Required`,
    },
    issue_date_must_be_in_the_past: {
      code: 182,
      message: `issue_date_must_be_in_the_past`,
      text: `Issue Date Must Be In The Past`,
    },
    wrong_file_extension: {
      code: 183,
      message: `wrong_file_extension`,
      text: `Wrong File Extension`,
    },
    download_file_error: {
      code: 184,
      message: `download_file_error`,
      text: `Download File Error`,
    },
    url_is_required: {
      code: 185,
      message: `url_is_required`,
      text: `Url Is Required`,
    },
    system_error: {
      code: 186,
      message: `system_error`,
      text: `System Error`,
    },
    validate_invoice_data: {
      code: 187,
      message: `validate_invoice_data`,
      text: `Validate Invoice Data`,
    },
    validate_pre_sell_parsed_invoices: {
      code: 188,
      message: `validate_pre_sell_parsed_invoices`,
      text: `Validate Pre Sell Parsed Invoices`,
    },
    managed_funds_already_exists_for_pair_funder_fund_manager: {
      code: 189,
      message: `managed_funds_already_exists_for_pair_funder_fund_manager`,
      text: `Managed Funds Already Exists For Pair Funder Fund Manager`,
    },
    can_not_insert_managed_fund_into_the_database: {
      code: 190,
      message: `can_not_insert_managed_fund_into_the_database`,
      text: `Can Not Insert Managed Fund Into The Database`,
    },
    error_while_updating_invoice_seller: {
      code: 191,
      message: `error_while_updating_invoice_seller`,
      text: `Error While Updating Invoice Seller`,
    },
    managed_fund_funder_id_is_not_equal_to_funder_id: {
      code: 192,
      message: `managed_fund_funder_id_is_not_equal_to_funder_id`,
      text: `Managed Fund Funder Id Is Not Equal To Funder Id`,
    },
    managed_fund_status_already_approved: {
      code: 193,
      message: `managed_fund_status_already_approved`,
      text: `Managed Fund Status Already Approved`,
    },
    funder_is_not_a_fund_manager: {
      code: 194,
      message: `funder_is_not_a_fund_manager`,
      text: `Funder Is Not A Fund Manager`,
    },
    funder_fund_manager_entity_id_is_not_equal_to_managed_fund_fund_manager_id: {
      code: 195,
      message: `funder_fund_manager_entity_id_is_not_equal_to_managed_fund_fund_manager_id`,
      text: `Funder Fund Manager Entity Id Is Not Equal To Managed Fund Fund Manager Id`,
    },
    managed_fund_can_not_be_updated_it_already_has_status: {
      code: 196,
      message: `managed_fund_can_not_be_updated_it_already_has_status`,
      text: `Managed Fund Can Not Be Updated It Already Has Status`,
    },
    currency_is_duplicate: {
      code: 197,
      message: `currency_is_duplicate`,
      text: `Currency Is Duplicate`,
    },
    can_not_insert_management_funds_limit_into_the_database: {
      code: 198,
      message: `can_not_insert_management_funds_limit_into_the_database`,
      text: `Can Not Insert Management Funds Limit Into The Database`,
    },
    can_not_update_management_funds_limit_in_the_database: {
      code: 199,
      message: `can_not_update_management_funds_limit_in_the_database`,
      text: `Can Not Update Management Funds Limit In The Database`,
    },
    management_funds_limit_not_found: {
      code: 200,
      message: `management_funds_limit_not_found`,
      text: `Management Funds Limit Not Found`,
    },
    limit_managed_fund_funder_id_is_not_equal_to_funder_id: {
      code: 201,
      message: `limit_managed_fund_funder_id_is_not_equal_to_funder_id`,
      text: `Limit Managed Fund Funder Id Is Not Equal To Funder Id`,
    },
    only_funded_invoice_may_be_marked_as_repaid: {
      code: 202,
      message: `only_funded_invoice_may_be_marked_as_repaid`,
      text: `Only Funded Invoice May Be Marked As Repaid`,
    },
    too_many_attempts_to_find_unique_ref_code: {
      code: 203,
      message: `too_many_attempts_to_find_unique_ref_code`,
      text: `Too Many Attempts To Find Unique Ref Code`,
    },
    invoice_repayment_reference_already_attached: {
      code: 204,
      message: `invoice_repayment_reference_already_attached`,
      text: `Invoice Repayment Reference Already Attached`,
    },
    invoice_repayment_reference_can_not_be_attached: {
      code: 205,
      message: `invoice_repayment_reference_can_not_be_attached`,
      text: `Invoice Repayment Reference Can Not Be Attached`,
    },
    all_invoices_must_be_in_one_currency: {
      code: 206,
      message: `all_invoices_must_be_in_one_currency`,
      text: `All Invoices Must Be In One Currency`,
    },
    invoice_status_direct_repayment_started: {
      code: 207,
      message: `invoice_status_direct_repayment_started`,
      text: `Invoice Status Direct Repayment Started`,
    },
    default_account_is_required: {
      code: 208,
      message: `default_account_is_required`,
      text: `Default Account Is Required`,
    },
    account_not_found: {
      code: 209,
      message: `account_not_found`,
      text: `Account Not Found`,
    },
    payments_metadata_not_found_for_beneficiary: {
      code: 210,
      message: `payments_metadata_not_found_for_beneficiary`,
      text: `Payments Metadata Not Found For Beneficiary`,
    },
    payer_payment_task_must_be_set: {
      code: 211,
      message: `payer_payment_task_must_be_set`,
      text: `Payer Payment Task Must Be Set`,
    },
    assets_ids_is_empty: {
      code: 212,
      message: `assets_ids_is_empty`,
      text: `Assets Ids Is Empty`,
    },
    assets_not_found_for_payer_payment: {
      code: 213,
      message: `assets_not_found_for_payer_payment`,
      text: `Assets Not Found For Payer Payment`,
    },
    strategies_not_found: {
      code: 214,
      message: `strategies_not_found`,
      text: `Strategies Not Found`,
    },
    can_not_insert_invoice_payer_into_the_database: {
      code: 215,
      message: `can_not_insert_invoice_payer_into_the_database`,
      text: `Can Not Insert Invoice Payer Into The Database`,
    },
    can_not_update_invoice_payer_in_the_database: {
      code: 216,
      message: `can_not_update_invoice_payer_in_the_database`,
      text: `Can Not Update Invoice Payer In The Database`,
    },
    can_not_update_invoice_payers_in_the_database: {
      code: 217,
      message: `can_not_update_invoice_payers_in_the_database`,
      text: `Can Not Update Invoice Payers In The Database`,
    },
    report_not_found: {
      code: 218,
      message: `report_not_found`,
      text: `Report Not Found`,
    },
    default_seller_payer_metadata_exists: {
      code: 219,
      message: `default_seller_payer_metadata_exists`,
      text: `Default Seller Payer Metadata Exists`,
    },
    can_not_insert_seller_payer_metadata_into_the_database: {
      code: 220,
      message: `can_not_insert_seller_payer_metadata_into_the_database`,
      text: `Can Not Insert Seller Payer Metadata Into The Database`,
    },
    link_between_seller_and_payer_exists: {
      code: 221,
      message: `link_between_seller_and_payer_exists`,
      text: `Link Between Seller And Payer Exists`,
    },
    payer_id_or_default_payer_id_query_param_required: {
      code: 222,
      message: `payer_id_or_default_payer_id_query_param_required`,
      text: `Payer Id Or Default Payer Id Query Param Required`,
    },
    seller_payer_metadata_not_found: {
      code: 223,
      message: `seller_payer_metadata_not_found`,
      text: `Seller Payer Metadata Not Found`,
    },
    can_not_insert_seller_payment_into_the_database: {
      code: 224,
      message: `can_not_insert_seller_payment_into_the_database`,
      text: `Can Not Insert Seller Payment Into The Database`,
    },
    your_role_is_not_not_fully_registered: {
      code: 225,
      message: `your_role_is_not_not_fully_registered`,
      text: `Your Role Is Not Not Fully Registered`,
    },
    seller_is_already_created: {
      code: 226,
      message: `seller_is_already_created`,
      text: `Seller Is Already Created`,
    },
    only_seller_can_create_role: {
      code: 227,
      message: `only_seller_can_create_role`,
      text: `Only Seller Can Create Role`,
    },
    prod_and_service_id_list_some_records_do_not_exist: {
      code: 228,
      message: `prod_and_service_id_list_some_records_do_not_exist`,
      text: `Prod And Service Id List Some Records Do Not Exist`,
    },
    get_seller_filter_must_be_set_null_provided: {
      code: 229,
      message: `get_seller_filter_must_be_set_null_provided`,
      text: `Get Seller Filter Must Be Set Null Provided`,
    },
    elements_of_total_by_statuses_must_be_valid_invoice_status: {
      code: 230,
      message: `elements_of_total_by_statuses_must_be_valid_invoice_status`,
      text: 'elements_of_total_by_statuses_must_be_valid_invoice_status',
    },
    table_is_required: {
      code: 231,
      message: `table_is_required`,
      text: `Table Is Required`,
    },
    user_uuid_is_required: {
      code: 232,
      message: `user_uuid_is_required`,
      text: `User Uuid Is Required`,
    },
    user_name_is_required: {
      code: 233,
      message: `user_name_is_required`,
      text: `User Name Is Required`,
    },
    invoice_amount_must_be_set_in_usd_currency: {
      code: 234,
      message: `invoice_amount_must_be_set_in_usd_currency`,
      text: `Invoice Amount Must Be Set In Usd Currency`,
    },
    this_context_action_type_is_required_please_see_context_class_and_the_method_than_trigger_decline_invoice_action: {
      code: 235,
      message: `this_context_action_type_is_required_please_see_context_class_and_the_method_than_trigger_decline_invoice_action`,
      text: `This Context Action Type Is Required Please See Context Class And The Method Than Trigger Decline Invoice Action`,
    },
    confirmed_by_is_required_please_set_it_earlier: {
      code: 236,
      message: `confirmed_by_is_required_please_set_it_earlier`,
      text: `Confirmed By Is Required Please Set It Earlier`,
    },
    db_instance_must_be_send_to_validator_constructor_in_order_to_make_check_exists_check: {
      code: 237,
      message: `db_instance_must_be_send_to_validator_constructor_in_order_to_make_check_exists_check`,
      text: `Db Instance Must Be Send To Validator Constructor In Order To Make Check Exists Check`,
    },
    db_instance_must_be_send_to_validator_constructor_in_order_to_make_check_unique_check: {
      code: 238,
      message: `db_instance_must_be_send_to_validator_constructor_in_order_to_make_check_unique_check`,
      text: `Db Instance Must Be Send To Validator Constructor In Order To Make Check Unique Check`,
    },
    db_instance_must_be_send_to_validator_constructor_in_order_to_make_check_unique_in_existed_check: {
      code: 239,
      message: `db_instance_must_be_send_to_validator_constructor_in_order_to_make_check_unique_in_existed_check`,
      text: `Db Instance Must Be Send To Validator Constructor In Order To Make Check Unique In Existed Check`,
    },
    fund_manager_id_is_required: {
      code: 240,
      message: `fund_manager_id_is_required`,
      text: `Fund Manager Id Is Required`,
    },
    portal_admin_could_not_edit_the_invoices: {
      code: 241,
      message: `portal_admin_could_not_edit_the_invoices`,
      text: `Portal Admin Could Not Edit The Invoices`,
    },
    create_seller_order_form_error: {
      code: 242,
      message: `create_seller_order_form_error`,
      text: `Create Seller Order Form Error`,
    },
    can_not_create_funder: {
      code: 243,
      message: `can_not_create_funder`,
      text: `Can Not Create Funder`,
    },
    can_not_create_investment_strategy: {
      code: 244,
      message: `can_not_create_investment_strategy`,
      text: `Can Not Create Investment Strategy`,
    },
    can_not_create_managed_strategy: {
      code: 245,
      message: `can_not_create_managed_strategy`,
      text: `Can Not Create Managed Strategy`,
    },
    can_not_update_managed_strategy: {
      code: 246,
      message: `can_not_update_managed_strategy`,
      text: `Can Not Update Managed Strategy`,
    },
    can_not_update_invoices: {
      code: 247,
      message: `can_not_update_invoices`,
      text: `Can Not Update Invoices`,
    },
    can_not_update_strategies: {
      code: 248,
      message: `can_not_update_strategies`,
      text: `Can Not Update Strategies`,
    },
    can_not_update_payer: {
      code: 249,
      message: `can_not_update_payer`,
      text: `Can Not Update Payer`,
    },
    can_not_create_company_in_sso: {
      code: 250,
      message: `can_not_create_company_in_sso`,
      text: `Can Not Create Company In Sso`,
    },
    can_not_update_company_in_sso: {
      code: 251,
      message: `can_not_update_company_in_sso`,
      text: `Can Not Update Company In Sso`,
    },
    can_not_insert_seller_into_the_database: {
      code: 252,
      message: `can_not_insert_seller_into_the_database`,
      text: `Can Not Insert Seller Into The Database`,
    },
    can_not_create_seller: {
      code: 253,
      message: `can_not_create_seller`,
      text: `Can Not Create Seller`,
    },
    can_not_get_user_profile: {
      code: 254,
      message: `can_not_get_user_profile`,
      text: `Can Not Get User Profile`,
    },
    can_not_start_checkout: {
      code: 255,
      message: `can_not_start_checkout`,
      text: `Can Not Start Checkout`,
    },
    can_not_create_payer: {
      code: 256,
      message: `can_not_create_payer`,
      text: `Can Not Create Payer`,
    },
    must_be_mandatory_for_one_of_company_type: {
      code: 257,
      message: 'must_be_mandatory_for_one_of_company_type',
      text: 'Must Be Mandatory For One Of Company Type',
    },
    validator_not_initialized: {
      code: 258,
      message: 'validator_not_initialized',
      text: 'Validator Not Initialized',
    },
    you_have_no_relationship_with_seller: {
      code: 259,
      message: 'you_have_no_relationship_with_seller',
      text: 'You Have No Relationship With Seller',
    },
    you_have_no_relationship_with_payer: {
      code: 260,
      message: 'you_have_no_relationship_with_payer',
      text: 'You Have No Relationship With Payer',
    },
    reg_no_or_tax_id_or_name_is_busy: {
      code: 261,
      message: 'reg_no_or_tax_id_or_name_is_busy',
      text: 'Reg No Or Tax Id Or Name Is Busy',
    },
    invoices_workflow_settings_not_found: {
      code: 262,
      message: 'invoices_workflow_settings_not_found',
      text: 'Invoices Workflow Settings Not Found',
    },
  };
}
