import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthenticationService } from '@app/core/services';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const navigate: any = { url: state.url.split('?')[0] };
    navigate['queryParams'] = route.queryParams;
    // console.log('AuthenticationGuard -> canActivate -> navigate', navigate);
    // this.storage.setAuthRedirectURL(navigate);

    if (!!this.authenticationService.tokenValue) {
      return true;
    }

    this.router.navigate(['login'], { replaceUrl: true });
    return false;
  }
}
