import { Injectable } from '@angular/core';

import { EMPTY, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, tap } from 'rxjs/operators';

import type { HelpHero } from 'helphero';
import initHelpHero from 'helphero';

import { environment } from '@env/environment';

import { AuthenticationService, UserProfile } from '@app/core/services';

export interface IdentifyUserData {
  // Identify method Data type which isn't exported from the helphero library
  [key: string]: boolean | number | string | undefined | null;
  id: string;
  email: string;
  role: string;
  companyType: string;
}

/**
 * JavaScript API
 * @link  https://helphero.co/docs/javascript/
 * */
@Injectable({
  providedIn: 'root',
})
export class HelpHeroService {
  helpHero: HelpHero | undefined;
  subscription: Subscription = Subscription.EMPTY;

  constructor(private authService: AuthenticationService) {}

  init(openChecklist: boolean = false): void {
    this.helpHero = initHelpHero(environment.helpHeroAppID);

    this.subscription = this.authService.user$
      .pipe(
        distinctUntilChanged((previous, current) => previous?.company_uuid === current?.company_uuid),
        tap((user) => {
          if (user) {
            const identifyUserData = this.createIdentifyData(user);
            this.helpHero!.identify(identifyUserData.id, identifyUserData);
          }

          if (openChecklist) {
            this.helpHero!.openChecklist();
          }
        }),
        catchError(() => EMPTY)
      )
      .subscribe();
  }

  createIdentifyData(user: UserProfile): IdentifyUserData {
    return <IdentifyUserData>{
      id: user.company_uuid,
      email: user.email,
      companyType: user.company_type,
      role: user.role,
    };
  }
}
