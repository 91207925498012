import { Inject, Injectable, Injector } from '@angular/core';

import { TuiNotification } from '@taiga-ui/core';

import { ApolloLink, DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { NamedOptions } from 'apollo-angular';
import { HttpLink, Options as HttpLinkOptions } from 'apollo-angular/http';

// @ts-ignore
import extractFiles from 'extract-files/extractFiles.mjs';
// @ts-ignore
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

import { environment } from '@env/environment';

import { NotificationsService } from '@app/core/services';
import { isStringFull } from '@app/core/services/helpers.service';

import {
  ActionLogServiceErrors,
  DocumentServiceErrors,
  KYCServiceErrors,
  PaymentServiceErrors,
} from './graphql-api-errors';
import { SentryReporterService } from '@app/core/sentry';

export enum GraphQlClientName {
  Payment = 'payment',
  Documents = 'documents',
  Notifications = 'notifications',
  Kyc = 'kyc',
  ActionLog = 'action-log',
}

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  private readonly httpLinkOptions: Record<GraphQlClientName, HttpLinkOptions> = {
    [GraphQlClientName.Payment]: {
      uri: environment.paymentGraphQLUrl,
      extractFiles: (body) => extractFiles(body, isExtractableFile),
    },
    [GraphQlClientName.Documents]: {
      uri: environment.documentGraphQLUrl,
      extractFiles: (body) => extractFiles(body, isExtractableFile),
    },
    [GraphQlClientName.Notifications]: {
      uri: environment.notificationGraphQLUrl,
      extractFiles: (body) => extractFiles(body, isExtractableFile),
    },
    [GraphQlClientName.Kyc]: {
      uri: environment.kycGraphQLUrl,
      extractFiles: (body) => extractFiles(body, isExtractableFile),
    },
    [GraphQlClientName.ActionLog]: {
      uri: environment.actionLogGraphQlUrl,
      extractFiles: (body) => extractFiles(body, isExtractableFile),
    },
  };

  private readonly defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  private readonly errorLink = onError(({ graphQLErrors, networkError, operation, forward, response }) => {
    const excludedRequests = ['validateUkAccountNumber'];
    if (graphQLErrors) {
      let errorMessage: any = `Unknown error`;
      for (const error of graphQLErrors) {
        if ((error?.message as any)['statusCode'] === 403 || error.message === 'Forbidden resource') {
          // this.toastr.error('Forbidden request. Please contact finverity technical team to deal with this problem.');
          console.log('Forbidden request. Please contact finverity technical team to deal with this problem.');
          return;
        }

        if (!!error && !!error.message && error.message === 're_login_needed') {
          if (!isStringFull(localStorage.getItem('ACCESS_TOKEN'))) {
            return;
          }

          errorMessage = 'Your session has expired. Please login again.';
        }

        console.log('GraphqlService -> errorLink -> error', error);
        let exclude: any;
        if (!!error && !!error.path) {
          // @ts-ignore
          exclude = excludedRequests.find((path) => path === error.path[0]);
        }
        if (!!exclude) {
          return;
        }
        let errorData = error.message as any;

        if (error?.extensions?.exception?.response?.message === error?.message) {
          errorData = error?.extensions?.exception?.response;
        }

        if (
          errorData.error === 'Unprocessable Entity' &&
          errorData.message.includes('Document with') &&
          errorData.message.includes('do not belong to the user')
        ) {
          errorMessage = errorData.message;
        }

        if (errorData === 'Internal server error') {
          errorMessage = errorData;
          return;
        }

        Object.entries(PaymentServiceErrors).forEach((entry: any) => {
          if (errorData.message === entry[1].message || errorData === entry[1].message) {
            if (!!errorData.context_data) {
              errorMessage = entry[1].text(errorData.context_data);
              return;
            }
            errorMessage = entry[1].text;
          }
        });
        Object.entries(DocumentServiceErrors).forEach((entry) => {
          if (errorData.message === entry[1].message || errorData === entry[1].message) {
            errorMessage = entry[1].text;
          }
        });
        Object.entries(KYCServiceErrors).forEach((entry) => {
          if (errorData.message === entry[1].message || errorData === entry[1].message) {
            errorMessage = entry[1].text;
          }
        });
        Object.entries(ActionLogServiceErrors).forEach((entry) => {
          if (errorData.message === entry[1].message || errorData === entry[1].message) {
            errorMessage = entry[1].text;
          }
        });
        switch (errorData.message) {
          case PaymentServiceErrors.TransactionQueued.message:
            // errorMessage = PaymentServiceErrors.TransactionQueued.text(errorData.context_data.execution_date);
            break;

          default:
            break;
        }
        this.notificationService.showNotification(errorMessage, TuiNotification.Error).subscribe();
        console.log('GraphqlService -> errorLink -> errorMessage', errorMessage);
      }
    }
    if (networkError) {
      const message = 'Network connection error';
      console.log('TCL: errorLink -> networkError', networkError);
    }

    this.sentryReporterService.reportGraphqlErrors({
      graphQLErrors,
      networkError,
      operation,
      forward,
      response,
    });
  });

  constructor(
    private readonly httpLink: HttpLink,
    private readonly notificationService: NotificationsService,
    @Inject(Injector) private injector: Injector,
    private readonly sentryReporterService: SentryReporterService
  ) {}

  /**
   * Init Client by providing APOLLO_NAMED_OPTIONS InjectionToken
   * */
  getNamedClientOptions(): NamedOptions {
    const defaultOptions = this.defaultOptions;

    return {
      [GraphQlClientName.Payment]: {
        link: this.createHttpLink(GraphQlClientName.Payment),
        cache: new InMemoryCache(),
        defaultOptions,
      },
      [GraphQlClientName.Documents]: {
        link: this.createHttpLink(GraphQlClientName.Documents),
        cache: new InMemoryCache(),
        defaultOptions,
      },
      [GraphQlClientName.Notifications]: {
        link: this.createHttpLink(GraphQlClientName.Notifications),
        cache: new InMemoryCache(),
        defaultOptions,
      },
      [GraphQlClientName.Kyc]: {
        link: this.createHttpLink(GraphQlClientName.Kyc),
        cache: new InMemoryCache(),
        defaultOptions,
      },
      [GraphQlClientName.ActionLog]: {
        link: this.createHttpLink(GraphQlClientName.ActionLog),
        cache: new InMemoryCache(),
        defaultOptions,
      },
    };
  }

  private createHttpLink(clientName: GraphQlClientName): ApolloLink {
    return this.errorLink.concat(this.httpLink.create(this.httpLinkOptions[clientName]));
  }
}
